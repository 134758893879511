import { FeedTypeEnum } from '@/types'
import type { BadgeVariants } from '@/ui'

export const FEED_TITLES: Record<FeedTypeEnum, string> = {
  [FeedTypeEnum.CALL]: 'Incoming Call',
  [FeedTypeEnum.INQUIRY]: 'Submitted Form',
  [FeedTypeEnum.MESSAGE]: 'Message',
  [FeedTypeEnum.CALL_NOTE]: 'Call Note',
  [FeedTypeEnum.NOTE]: 'Note',
  [FeedTypeEnum.FILE]: 'Files',
  [FeedTypeEnum.EVENT]: 'Appointment',
  [FeedTypeEnum.CLIENT_CHANGES]: 'Client Changes',
}

export const FEED_COLORS: Record<FeedTypeEnum, BadgeVariants['variant']> = {
  [FeedTypeEnum.CALL]: 'yellow',
  [FeedTypeEnum.INQUIRY]: 'green',
  [FeedTypeEnum.MESSAGE]: 'blue',
  [FeedTypeEnum.CALL_NOTE]: 'purple',
  [FeedTypeEnum.NOTE]: 'indigo',
  [FeedTypeEnum.FILE]: 'pink',
  [FeedTypeEnum.EVENT]: 'red',
  [FeedTypeEnum.CLIENT_CHANGES]: 'lime',
}
